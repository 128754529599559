import classnames from 'classnames';
import React from 'react';
import validate from 'validate.js';
import Select from 'react-select';
import _ from 'lodash-es';
import '../accordion.scss';
import UpArrow from '../library-components/Icons/UpArrow';
import DownArrow from '../library-components/Icons/DownArrow';
import { Button, ButtonGroup, Alert, SelectFieldWithLabel, TextFieldWithLabel, TextAreaWithLabel, Table } from '../library-components';
import currencyUtils, { ALL_CURRENCIES } from '../../helpers/currencyUtils';
import { getRefunds,
  createRefund,
  patchSubscriptionForSubscriber,
  cancelSubscriberSubscription,
  postRenewSubscription,
  patchExtendSubscription } from '../../api';
import Invoice from '../invoice/invoice';
import { getDateFromISO } from '../../helpers/dateUtils';
import { DATE } from '../../constants';
import ActionsMenu from '../actions-menu';
import ExtendSubscription from '../../components/extend-subscription';

const REFUND_PAYMENT_TYPES = ['razorpay', 'paypal', 'omise', 'adyen', 'paytrail'];
const NON_PAYMENT_GATEWAY_PAYMENT_TYPES = ['manual', 'sponsored', 'reward', 'skip_payment_gateway', 'bundle', 'bundle_recurring'];
const AWAITING_PAYMENT = 'Awaiting Payment';
// payment types who charge for pending recurring subscriptions
const PENDING_RECURRING_CHARGE_PAYMENT_TYPES = [
  'adyen_recurring',
  'omise_recurring'
];
const constraints = {
  refundAmount: function (value, attributes) {
    return {
      presence: true,
      numericality: {
        strict: true,
        greaterThanOrEqualTo: 100,
        lessThanOrEqualTo: attributes.subscriptionPrice,
        message: 'should be greater than 1 and less than amount paid'
      }
    };
  }
};

class Subscription extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      subscription: this.props.subscription,
      isMetadataEditable: false,
      refundAmount: currencyUtils.convertToCurrencyUnits(this.props.subscription.payment_amount_cents),
      refundOpen: false,
      showExtendDialog: false,
      collapsed: this.props.collapsed
    };
  }

  componentDidMount () {
    this.getRefunds();
  }

  componentDidUpdate (prevProps) {
    if (this.props.subscription !== prevProps.subscription) {
      this.setState({ subscription: this.props.subscription });
    }
  }

  getRefunds () {
    getRefunds(this.props.provider, this.props.identity, this.props.subscription.id)
      .then(refunds => this.setState({ refunds: refunds }));
  }

  resetSubscription () {
    this.setState({ subscription: this.props.subscription, isMetadataEditable: false });
  }

  getMetadataFields (subscription) {
    if (!_.isEmpty(this.props.allSubscriptionGroups)) {
      var subscriptionGroup = _.find(this.props.allSubscriptionGroups, (group) => group.id === subscription['subscription_group_id']);
      return subscriptionGroup ? subscriptionGroup['metadata_fields'] : Object.keys(subscription['metadata']);
    }
  }

  isEditable () {
    return !['cancelled', 'expired'].includes(this.props.subscription['status']) && !this.state.isMetadataEditable;
  }

  isRefundable () {
    return REFUND_PAYMENT_TYPES.includes(this.props.subscription.payment_type) && !this.props.subscription['expired'];
  }

  getSubscriptionMetadata (subscription) {
    const metadataFieldsOfGroup = this.getMetadataFields(subscription);

    const metadataFields = _.chunk(_.compact(_.map(metadataFieldsOfGroup, (value, index) => _.has(subscription['metadata'], value) ? { key: value, value: subscription['metadata'][value] } : { key: value, value: '' })), 2);

    return _.map(metadataFields, (fields, index) =>
      (<div className='form-row' key={index} >
        {_.map(fields, (field, index) =>
          <TextFieldWithLabel label={field.key}
            disabled={this.state.isMetadataEditable === false}
            placeholder={field.key}
            key={index}
            onChange={(e) => this.updateField(field.key, e.target.value)}
            value={field.value} />
        )}
      </div>)
    );
  }

  updateField (field, value) {
    const metadata = this.state.subscription['metadata'];
    const newMetadata = Object.assign({}, metadata, { [field]: value });
    const newSubscription = Object.assign({}, this.state.subscription, { metadata: newMetadata });

    this.setState({ subscription: newSubscription });
  }

  updateNotes (value) {
    const newSubscription = Object.assign({}, this.state.subscription, { notes: value });
    this.setState({ subscription: newSubscription });
  }

  onChangeRefundAmount (value) {
    this.setState({ refundAmount: value });
  }

  openAlert () {
    this.setState({ isAlertOpen: true });
  }

  closeAlert () {
    this.setState({ isAlertOpen: false });
  }

  refundSubscription () {
    var self = this;
    const refundAmount = currencyUtils.convertToLowestDenomination(this.state.refundAmount);
    const errors = validate({ refundAmount: refundAmount, subscriptionPrice: this.props.subscription['plan_amount_cents'] }, constraints);
    this.setState({ errors });
    if (errors) {
      return;
    }
    createRefund(self.props.provider, self.props.identity, this.props.subscription.id, refundAmount)
      .then((success) => self.props.setNotification(true, 'success', 'Refund successful!'),
        (error) => self.props.setNotification(true, 'failure', error.message))
      .then(() => self.getRefunds())
      .then(() => self.props.loadSubscriptionData());
  }

  saveSubscription (subscription) {
    var self = this;
    patchSubscriptionForSubscriber(this.props.provider, this.props.identity, subscription.id, { metadata: subscription.metadata, notes: subscription.notes })
      .then(() => {
        self.props.setNotification(true, 'success', 'Subscription Updated!');
        self.props.updateSubscription(subscription);
        this.setState({ isMetadataEditable: false });
      }, errors => {
        const errorMessage = _.get(errors, ['error', 'message'], 'Cannot update metadata');
        self.props.setNotification(true, 'failure', errorMessage);
      }).then(() => self.props.loadSubscriptionData());
  }

  extendSubscription (subscription, subscriptionExtendToValue) {
    var self = this;
    patchExtendSubscription(this.props.provider, this.props.identity, subscription.id, { extend_to: subscriptionExtendToValue })
      .then(() => {
        self.props.setNotification(true, 'success', `Subscription ID ${subscription.id} has been Extended`);
        self.props.updateSubscription(subscription);
      }, errors => {
        const errorMessage = _.get(errors, ['error', 'message'], 'Cannot extend subscription');
        self.props.setNotification(true, 'failure', errorMessage);
      }).then(() => self.props.loadSubscriptionData());
  }

  cancelSubscription (e) {
    e.preventDefault();
    var self = this;
    cancelSubscriberSubscription(this.props.provider, this.props.identity, this.state.subscription['id'])
      .then((response) => {
        self.props.setNotification(true, 'success', 'Subscription cancelled successfully.');
        window.location.reload();
      },
      (error) => self.props.setNotification(true, 'failure', error));
  }

  renewSubscription (id) {
    var self = this;

    postRenewSubscription(this.props.provider, this.props.identity, id)
      .then((success) => self.props.setNotification(true, 'success', 'Subscription successfully renewed'),
        (error) => self.props.setNotification(true, 'failed', error.Message))
      .then(() => self.props.loadSubscriptionData());
  }

  getCancellationMessage () {
    if (['cancelled', 'expired'].includes(this.props.subscription.status) || this.props.subscription.cancelled) { return ''; }

    if (!this.props.subscription.recurring) {
      return 'Are you sure, you want to cancel this One Time Subscription? This will be cancelled effective immediately.';
    }

    const nextDate = this.props.subscription.status === 'pending' && !PENDING_RECURRING_CHARGE_PAYMENT_TYPES.includes(this.props.subscription.payment_type)
      ? getDateFromISO(this.props.subscription['start_timestamp'], DATE.DEFAULT_FMT)
      : getDateFromISO(this.props.subscription['next_payment_due_date'], DATE.DEFAULT_FMT);

    return `The cancellation will be effective on ${nextDate}. Are you sure you want to cancel this recurring subscription?`;
  }

  statusClass (status) {
    return classnames('subscriber-subscription__status', `subscriber-subscription__status--${status}`);
  }

  activityLog (activityLog) {
    return (<Table header={['Identity', 'Action', 'Date and Time']} className='table-default--3cols activity-log-table'>
      {activityLog.map((log, index) =>
        <div key={index} className='table-default__row'>
          <div className='table-default__cell'>{log['identity']}</div>
          <div className='table-default__cell'>{_.capitalize(log['action'])}</div>
          <div className='table-default__cell'>{getDateFromISO(log['timestamp'], DATE.HR_MIN_FMT)}</div>
        </div>
      )}
    </Table>);
  }

  render () {
    const { errors } = this.state;
    const cancellationMessage = this.getCancellationMessage();
    const cardClass = classnames('subscription-container', 'accordion-item accordion-item--white', { 'accordion-item--collapsed accordion__item--min_height_80': true, 'accordion-item--expand': !this.state.collapsed });
    const iconClass = classnames('accordion-item--collapse-icon');

    return (
      <>
        <div className={cardClass}>
          <div className='accordion-item__header accordion-item-header' onClick={() => this.setState({ collapsed: !this.state.collapsed })}>
            <div className='accordion-item-header__left'>
              <div className='subscription-info'>
                <h3 className='subscriber-subscription__title' >
                  {this.props.subscription['plan_name']}
                </h3>
                <span className={this.statusClass(this.props.subscription['status'])}>
                  {this.props.subscription['status']}
                </span>
                {!this.props.subscription.cancelled && this.props.subscription.extended && <span className={this.statusClass('extended')}>Extended</span>}
                {this.props.subscription['in_grace_period'] && (
                  <span className={classnames('subscriber-subscription__status', 'subscriber-subscription__status--awaiting-payment')}>
                    {AWAITING_PAYMENT}
                  </span>
                )}
              </div>
              {this.state.collapsed && <ul className=' accordion-item-header__description subscription-group-details'>
                <li>
                  <div className='subscription-info-header'>
                    <span className='subscription-header-info-box'><p className='subscription-header-info-box__key'>Amount:</p> <p className='subscription-header-info-box__value'>{this.props.subscription['plan_amount_cents']}</p></span>
                    <span className='subscription-header-info-box'><p className='subscription-header-info-box__key'>Occurence:</p> <p className='subscription-header-info-box__value'>{this.props.subscription['plan_occurrence'].toUpperCase()}</p></span>
                    <span className='subscription-header-info-box'><p className='subscription-header-info-box__key'>Group:</p> <p className='subscription-header-info-box__value'>{this.props.subscription['group_name']}</p></span>
                  </div>
                </li>
              </ul>}
            </div>
            <div className='accordion-item-header__right'>
              <div className="subscription-action-menu">
                <ActionsMenu
                  data-test-id='test-actions'
                  item={this.props.subscription}
                  onClick={() => this.setState({ collapsed: false })}
                  onExtend={() => this.setState({ showExtendDialog: true })}
                  isEditable={this.isEditable()}
                  onEdit={() => this.setState({ isMetadataEditable: !this.state.isMetadataEditable })}
                  isCancellable={!this.props.subscription['expired'] && !this.props.subscription['cancelled'] && this.props.subscription['cancellable']}
                  onCancel={() => this.openAlert()}
                  isRefundable={this.isRefundable()}
                  onRefund={() => this.setState({ refundOpen: !this.state.refundOpen })}
                  isRenewable={this.props.subscription.renewable}
                  onRenew={() => this.renewSubscription(this.state.subscription['id'])}
                />
              </div>
              {<span className={iconClass}>{!this.state.collapsed ? <UpArrow /> : <DownArrow />}</span>}
            </div>
          </div>
          <div className='accordion-item__body'>
            {!this.state.isMetadataEditable &&
          (<><div className="form-row">
            <TextFieldWithLabel
              disabled
              label="Subscription Group"
              value={this.props.subscription['group_name']}
              dataTest="subscription-group-name"
            />
            <SelectFieldWithLabel
              disabled
              label="Subscription Plan"
              value={this.props.subscription['plan_name']}
              options={[
                {
                  label: this.props.subscription['plan_name'],
                  value: this.props.subscription['plan_name']
                }
              ]}
              placeholder={false}
              searchable={false}
              clearable={false}
              dataTest="subscription-plan-name"
            />
          </div>
          <div className="form-row">
            <TextFieldWithLabel
              disabled
              label="Start Date"
              defaultValue={getDateFromISO(this.props.subscription['start_timestamp'], DATE.MONTH_EXP_FMT)}
              dataTest="start-date"
            />
            {this.props.subscription['next_payment_due_date'] && this.props.subscription['next_payment_due_date'] !== null
              ? <TextFieldWithLabel
                disabled
                label='Next Payment Due Date'
                defaultValue={getDateFromISO(this.props.subscription['next_payment_due_date'], DATE.MONTH_EXP_FMT)}
                dataTest='next-payment-due-date'
              />
              : <TextFieldWithLabel
                disabled
                label='End Date'
                defaultValue={getDateFromISO(this.props.subscription['previous_end_timestamp'], DATE.MONTH_EXP_FMT)}
                dataTest='end-date'
              />}
          </div>
          <div className="form-row">
            {this.props.subscription['cancelled_at'] && (
              <TextFieldWithLabel
                disabled
                label="Cancellation Date"
                defaultValue={getDateFromISO(this.props.subscription['cancelled_at'], DATE.MONTH_EXP_FMT)}
                dataTest="cancelled-at"
              />
            )}
          </div>
          <div className="form-row">
            <SelectFieldWithLabel
              disabled
              label="Subscription Type"
              value={this.props.subscription['subscription_type']}
              options={[
                {
                  label: this.props.subscription['subscription_type'],
                  value: this.props.subscription['subscription_type']
                }
              ]}
              placeholder={false}
              searchable={false}
              clearable={false}
              dataTest="subscription-type"
            />
            <TextFieldWithLabel
              disabled
              data-test-id="occurrence"
              label="Plan Occurrence"
              value={this.props.subscription['plan_occurrence']}
              dataTest="occurrence"
            />
          </div>
          <div className="form-row">
            {NON_PAYMENT_GATEWAY_PAYMENT_TYPES.includes(this.props.subscription['payment_type']) ? (
              <TextFieldWithLabel
                disabled
                data-test-id="payment-type"
                dataTest="payment-type"
                label="Payment Type"
                value={this.props.subscription['payment_type'].includes('bundle') ? 'Bundle' : this.props.subscription['payment_type']}
              />
            ) : (
              <TextFieldWithLabel
                disabled
                data-test-id="payment-type"
                dataTest="payment-type"
                label="Payment Type"
                value="Payment Gateway"
              />
            )}
            <div className="text-and-select">
              <TextFieldWithLabel
                disabled
                data-test-id="plan_amount"
                dataTest="plan-amount"
                label="Plan Amount"
                value={currencyUtils.convertToCurrencyUnits(this.props.subscription['plan_amount_cents'])}
              />
              <Select
                className="select-currency"
                data-test-id="currency-flag"
                dataTest="currency-flag"
                value={this.props.subscription.plan_amount_currency || ''}
                options={ALL_CURRENCIES}
                clearable={false}
                searchable={false}
                disabled
                arrowRenderer={currencyUtils.arrowRenderer}
              />
            </div>
          </div>
          {!NON_PAYMENT_GATEWAY_PAYMENT_TYPES.includes(this.props.subscription['payment_type']) &&
        <div className='form-row'>
          <TextFieldWithLabel
            disabled
            label='Payment Gateway'
            dataTest='payment-gateway-name'
            value={this.props.subscription['payment_type'] && this.props.subscription['payment_type'].includes('recurring') ? this.props.subscription['payment_type'].replace('_recurring', '') : this.props.subscription['payment_type']}
          />
          <TextFieldWithLabel
            disabled
            label={this.props.subscription['payment_type'] && this.props.subscription['payment_type'].includes('recurring') ? 'Payment Gateway Subscription ID' : 'Payment Transaction ID'}
            value={this.props.subscription['payment_token']}
            dataTest='payment-gateway-subscription-id'
          />
        </div>}
          <div className="form-row">
            <TextFieldWithLabel
              disabled
              label="Coupon Code"
              placeholder="- N/A -"
              value={this.props.subscription['coupon_code']}
              dataTest="coupon-code"
            />
            <TextFieldWithLabel
              disabled
              label="Subscription Extended Till"
              placeholder="- N/A -"
              value={this.props.subscription.extended ? getDateFromISO(this.props.subscription['end_timestamp'], DATE.MONTH_EXP_FMT) : ''}
              dataTest="extended-till"
            />
          </div>
          <div className="form-row">
            <TextFieldWithLabel
              disabled
              label="Trial Period"
              placeholder="- N/A -"
              value={this.props.subscription['in_trial_period'] ? 'True (Free Trial)' : (this.props.subscription['in_paid_trial'] ? 'True (Paid Trail)' : 'False')}
              dataTest="trial-period"
            />
          </div>
          {this.state.subscription['subscription_type'] === 'dynamic_asset' && (
            <div className="form-row">
              <TextFieldWithLabel
                disabled
                label="Asset Title"
                placeholder="- N/A -"
                value={_.get(this.props.subscription, ['dynamic_assets', 0, 'title'], '')}
                dataTest="asset-title"
              />
              <TextFieldWithLabel
                disabled
                label="Asset Id"
                placeholder="- N/A -"
                value={_.get(this.props.subscription, ['dynamic_assets', 0, 'id'], '')}
                dataTest="asset-id"
              />
            </div>
          )}</>)}
            {this.getSubscriptionMetadata(this.state.subscription)}
            <TextAreaWithLabel
              disabled={this.state.isMetadataEditable === false}
              label="Notes"
              placeholder="- N/A -"
              value={this.state.subscription['notes']}
              dataTest="notes"
              onChange={(e) => this.updateNotes(e.target.value)}
            />

            <ButtonGroup columns={'one'}>
              <div>
                {this.state.isMetadataEditable && <Button onClick={() => this.resetSubscription()}>Cancel</Button>}
                {this.state.isMetadataEditable && <Button primary onClick={() => this.saveSubscription(this.state.subscription)}>Save</Button>}
              </div>
            </ButtonGroup>

            {!this.state.isMetadataEditable &&
        (<>{!_.isEmpty(this.state.refunds) && (
          <h3 className="subscriber-subscription">
            <span className="subscriber-subscription__title">Refund Details</span>
          </h3>
        )}
        {!_.isEmpty(this.state.refunds) && this.state.refunds.map((refund, index) =>
          <div key={index}>
            <div className='form-row'>
              <TextFieldWithLabel label={'External Refund ID'}
                disabled
                value={refund['external_refund_id']} />
              <div className='text-and-select'>
                <TextFieldWithLabel label={'Refund Amount'}
                  disabled
                  value={currencyUtils.convertToCurrencyUnits(refund['amount_cents'])}
                  dataTest='amount-refunded' />
                <Select className='select-currency'
                  data-test-id='currency-flag'
                  value={refund['amount_currency']}
                  options={ALL_CURRENCIES}
                  clearable={false}
                  searchable={false}
                  disabled
                  arrowRenderer={currencyUtils.arrowRenderer}
                  dataTest='amount-refunded-currency' />
              </div>
            </div>
            <div className='form-row'>
              <TextFieldWithLabel label={'Refunded by'}
                disabled
                value={refund['originator']}
                dataTest='refunded-by' />
              <TextFieldWithLabel disabled
                label='Refunded at'
                defaultValue={getDateFromISO(refund['created_at'], DATE.HR_MIN_FMT)}
                dataTest='refunded-at' />
            </div>
          </div>
        )}
        {!_.isEmpty(this.state.subscription.invoices) && (
          <h3 className="subscriber-subscription">
            <span className="subscriber-subscription__title">Payment & Invoice details</span>
          </h3>
        )}
        {!_.isEmpty(this.props.subscription.invoices) && (
          <Invoice
            invoices={this.props.subscription.invoices}
            identity={this.props.identity}
            provider={this.props.provider}
            subscription={this.props.subscription}
          />
        )}
        {this.props.subscription['activity_log'].length > 0 && (
          <div data-test="activity-log">
            <h3 className="subscriber-subscription">
              <span className="subscriber-subscription__title">Activity Logs</span>
            </h3>
            {this.activityLog(this.props.subscription['activity_log'])}</div>
        )}
        {!this.props.subscription['expired'] && !this.props.subscription['cancelled'] &&
          <Alert
            title="Cancel Subscription"
            open={this.state.isAlertOpen}
            destructive
            onCancel={() => this.closeAlert()}
            onConfirm={e => {
              this.setState({ isAlertOpen: false });
              this.cancelSubscription(e);
            }}
            buttonText={'Yes'}>
            {cancellationMessage}
          </Alert>
        }
        {/* Refund container starts here */}
        <div className={classnames('refund', { 'refund--open': this.state.refundOpen })}>
          <h4 className="refund__title">Refund</h4>
          <TextFieldWithLabel
            label="Amount"
            value={this.state.refundAmount || ''}
            onChange={e => this.onChangeRefundAmount(e.target.value)}
            errors={_.get(errors, ['refundAmount', '0'])}
            dataTest="refund-amount"
          />
          <p className="refund__text">Enter the amount to be refunded.</p>
          <ButtonGroup>
            <Button onClick={() => this.setState({ refundOpen: false })}>Cancel</Button>
            <Button primary onClick={() => this.refundSubscription()}>Refund</Button>
          </ButtonGroup>
        </div></>)}
            {/* Refund container ends here */}
          </div>
        </div>
        <ExtendSubscription
          isOpen={this.state.showExtendDialog}
          onClose={() => this.setState({ showExtendDialog: false })}
          onExtend={(extendTo) => this.extendSubscription(this.props.subscription, extendTo)}
          currentEndDateOfSubscription={getDateFromISO(this.state.subscription['end_timestamp'], DATE.YEAR_FIRST_FMT)}
        />
      </>
    );
  }
}

export default Subscription;
