import React from 'react';
import PropTypes from 'prop-types';
import {
  ContextMenu,
  ContextMenuList,
  ContextMenuItem
} from '@quintype/em/components/context-menu';
import { Button } from './library-components';

const ActionsMenu = ({ item, onClick, onExtend, isEditable, onEdit, isCancellable, onCancel, isRefundable, onRefund, isRenewable, onRenew }) => {
  return (
    <ContextMenu position={'bottomLeft'} icon={
      <Button secondary onClick={onClick} disabled={!(isEditable || isCancellable || isRefundable || isRenewable)}> Actions </Button>
    }>
      <ContextMenuList>
        {!item.recurring && !item.cancelled_at && onExtend && <ContextMenuItem
          label="Extend Subscription"
          onClick={onExtend}
        />}
        {isEditable && <ContextMenuItem
          label="Edit"
          onClick={onEdit}
        />}
        {isCancellable && <ContextMenuItem
          label="Cancel"
          onClick={onCancel}
        />}
        {isRenewable && <ContextMenuItem
          label="Renew"
          onClick={onRenew}
        />}
        {isRefundable && <ContextMenuItem
          label="Refund"
          onClick={onRefund}
        />}
      </ContextMenuList>
    </ContextMenu>
  );
};

ActionsMenu.propTypes = {
  item: PropTypes.any,
  onExtend: PropTypes.func
};

export default ActionsMenu;
