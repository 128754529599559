import React, { useState } from 'react';
import { Dialog } from '@quintype/em/components/dialog';
import { Radio, RadioOption } from '@quintype/em/components/radio-button';
import DatePicker from 'react-datepicker';
import { FaCalendarAlt } from 'react-icons/fa';
import './extend-subscription.scss';

export default function ExtendSubscription ({
  isOpen,
  currentEndDateOfSubscription,
  onClose,
  onExtend
}) {
  const [extendValueType, setExtendValueType] = useState('indefinitely');
  const [selectedDate, setSelectedDate] = useState('');
  const [showDateError, setShowDateError] = useState(false);

  const handleSelectedDateChange = (date) => {
    setShowDateError(false);
    setSelectedDate(date);
  };

  const handleExtendAction = () => {
    const extendTo = extendValueType === 'indefinitely' ? extendValueType : selectedDate;
    if (!extendTo) {
      setShowDateError(true);
      return;
    }
    onExtend(extendTo);
    onClose();
  };

  const minExtendDate = () => {
    const currDate = new Date();
    return currentEndDateOfSubscription && new Date(currentEndDateOfSubscription) > currDate ? new Date(currentEndDateOfSubscription) : currDate;
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      onAction={handleExtendAction}
      actionButtonLabel='Extend'
    >
      <div className='wrapper'>
        <h3 className='heading'>Extend Subscription</h3>
        <p className='description'>How long would you like to extend subscription? Extend indefinitely or choose a specific date.</p>
        <div className='radio-container'>
          <Radio
            align="vertical"
            selected={extendValueType}
            onChange={(e) => setExtendValueType(e.target.value)}
          >
            <RadioOption value="indefinitely">
              <p>Indefinitely</p>
            </RadioOption>
            <RadioOption value="custom">
              <div>
                <p>Custom</p>
                {extendValueType === 'custom' &&
                <div className='radio-button-description'>
                  <p className='heading-radio-button'>Extend Subscription Till</p>
                  <div className='date-picker-container'>
                    <DatePicker className='date-picker'
                      showIcon
                      selected={selectedDate}
                      onChange={handleSelectedDateChange}
                      minDate={minExtendDate()}
                      placeholderText="Click to select a date"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      scrollableYearDropdown
                      dateFormat="dd MMM yyyy"
                    />
                    <FaCalendarAlt size={20} className='date-picker-icon'/>
                  </div>
                  {showDateError && <p className='error-msg'>Please select date</p>}
                </div>
                }
              </div>
            </RadioOption>
          </Radio>
        </div>
        <div>

        </div>

      </div>
    </Dialog>
  );
}
